import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserRouter,
          RouterProvider } from 'react-router-dom';

// Import pages
import HomePage from './main/HomePage';
import BrowseTargets from './main/BrowseByTargets';
import ListPaperDir from './main/ListPaperDir';
import BrowseByPapers from './main/BrowseByPapers';
import ListTargetDir from './main/ListTargetDir';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/targets',
    element: <BrowseTargets />,
  },
  {
    path: '/targets/:path',
    element: <ListTargetDir />,
  },
  {
    path: '/papers',
    element: <BrowseByPapers />,
  },
  {
    path: '/targets/:target/:path',
    element: <ListPaperDir />,
  },

]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <div>
    <RouterProvider router={router} />
  </div>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
