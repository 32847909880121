import React from "react";
import { Container } from "react-bootstrap";
import "../App.css";
import NavBar from "../layout/NavBar";
import Footer from "../layout/Footer";

export default function BaseTemplate({children}) {
    return (
        <div> 
            <Container style={{margin:"auto", width:"90%", minHeight:"500px"}} fluid>
                <NavBar />
                    <Container style={{padding:"20px"}}> 
                        {children}
                    </Container>
                <Footer />
            </Container>
         
        </div>
    );
}