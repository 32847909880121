import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import "../App.css";
import BaseTemplate from "../layout/BaseTemplate";
import axios from "axios";

export default function BrowseByPapers() {
    const [papers, setPapers] = useState([]);

    function fetchPapers() {
        axios({
            method: 'GET',
            url: "/api/papers"
        })
        .then((response) => {
            // Sort the papers by year in descending order
            const sortedPapers = Object.keys(response.data)
                .sort((a, b) => parseInt(b) - parseInt(a))
                .reduce((sorted, year) => {
                    sorted.push({ year, papers: response.data[year] });
                    return sorted;
                }, []);
            setPapers(sortedPapers);
        })
        .catch((error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        fetchPapers();
    }, []);

    return (
        <BaseTemplate>
            <Row>
                <Col>
                    <h3>Papers</h3>
                    {papers.map(({ year, papers }) => (
                        <div key={year}>
                            <h5>{year}</h5>
                            <ul>
                                {papers.map((paper) => (
                                    <li key={paper.short_name}>
                                        {paper.citation}{' '}
                                        <a href={paper.url} target="_blank" rel="noopener noreferrer">
                                            [link]
                                        </a>
                                        <br />
                                        <br />
                                        <Row xs={4}>
                                            {paper.targets.map((target) => (
                                                <Col key={target}>
                                                    <Button className="soft" onClick={() => window.location.href = `/targets/${target}/${paper.short_name}`}>
                                                        {target}
                                                    </Button>
                                                </Col>
                                            ))}
                                        </Row>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </Col>
            </Row>
        </BaseTemplate>
    );
}
