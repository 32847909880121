import React, { useEffect } from 'react';
import {
        Container, 
        Navbar, 
        Nav, 
        } from 'react-bootstrap';

export default function NavBar() {
    useEffect(() => {
        document.title = "LSD";
    }, []);

    return (
        <Navbar bg="secondary" data-bs-theme="dark" expand="lg">
            <Container>
                <Navbar.Brand href="/">LSD</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="/targets">Browse by Target</Nav.Link>
                        <Nav.Link href="/papers">Browse by Paper</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}