import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import axios from "axios";
import BaseTemplate from "../layout/BaseTemplate";

export default function BrowseTargets() {
    const [targets, setTargets] = React.useState([]);
    
    React.useEffect(() => {
        axios
        .get("/api/targets")
        .then((response) => {
            setTargets(response.data);
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);
    
    return (
        <BaseTemplate>
        <h3>Targets</h3>
            <Row>
                {targets.map((target) => (
                    <Col lg={4} style={{'padding' : '5px'}} key={target.name}>
                        <Card>
                            <Card.Body className="justify-content-between">
                                <h4>{target.name}</h4>
                                <span><Button onClick={() => window.location.href = `/targets/${target.name}`}>View</Button></span>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </BaseTemplate>
    );
    }