import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { CDBTable, CDBTableHeader, CDBTableBody, CDBContainer } from 'cdbreact';


import BaseTemplate from '../layout/BaseTemplate';
import axios from 'axios';


function ListTargetDir() {
    const path = useParams().path;
    const [target, setTarget] = useState('');
    const [files, setFiles] = useState([]);
    const [reference, setReference] = useState([]);
    const [description, setDescription] = useState('');
    const [currentPath, setCurrentPath] = useState('');
  
    const fetchFiles = async (path) => {
      try {
          const response = await axios.get(`/api/targets/${path}`);
          const data = await response.data;
          setFiles(data.contents || []);
          setCurrentPath(path);
          setTarget(data.name);
          setReference(data.references);
          setDescription(data.description);
      } catch (error) {
          console.error('Error fetching files:', error);
      }
  };

    const handleFolderClick = (folderName) => {
        const newPath = currentPath === '' ? folderName : `${currentPath}/${folderName}`;
        fetchFiles(newPath);
    };

    const handleBackClick = () => {
        const newPath = currentPath.split('/').slice(0, -1).join('/');
        if (newPath === '') {
          window.location.href = '/targets';
          return;
        }
        fetchFiles(newPath || '');
    };
    const handleItemClick = (item) => {
        if (item.type === 'directory') {
          handleFolderClick(item.name);
        }
      };

    useEffect(() => {
      fetchFiles(path);
    }, []);

    return (
        <BaseTemplate>
        <h1>Browse LSD Results for {target}</h1>

        {description && (
            <p style={{ whiteSpace: 'pre-line' }}>{description}</p>
        )
        }
          {reference.length > 0 && (
          <div>
            <h5>Reference:</h5>
            <ul>
            {reference.map((ref) => (
                <li key={ref.short_name}>{ref.citation} <a target='_blank' rel="noopener noreferrer" href={ref.url}>[Link]</a></li>
            ))}
        </ul>
        </div>

        )
        }
        < hr />

        <p className='lead'>Current Path: {currentPath}</p>
        {currentPath && (
            <Button onClick={handleBackClick}>Back</Button>
        )}
        <br />
        <br />
        {files.length > 0 && (
        <CDBContainer>
          <CDBTable striped hover>
            <CDBTableHeader>
              <tr>
                <th>Name</th>
                <th>Last Modified</th>
                <th>Size</th>
              </tr>
            </CDBTableHeader>
            <CDBTableBody>
              {files.map((item, index) => (
                <tr style={{cursor: "pointer"}} key={index} onClick={() => handleItemClick(item)}>
                  <td>{item.type === 'directory' ? (
                    <i className="bi bi-folder">&nbsp;<span>{item.name}</span></i>
                  ) : (
                    <i className="bi bi-file-earmark">&nbsp;<span><a href={`/api/targets/${currentPath}/${item.name}`} download={item.name}>{item.name}</a></span></i>
                  )}</td>
                  <td>{item.last_modified}</td>
                  <td>{item.size}</td>
                </tr>
              ))}
            </CDBTableBody>
          </CDBTable>
        </CDBContainer>
        )}
        {files.length === 0 && (
            <i className="bi bi-exclamation-octagon">&nbsp;<span>No files found!</span></i>
        )}

        </BaseTemplate>
    );
}

export default ListTargetDir;
