import React from 'react';
import {    Row,
            Col,
            Card,
            Image } from 'react-bootstrap';
import BaseTemplate from '../layout/BaseTemplate';

export default function HomePage() {
    return (
        <BaseTemplate>
            <h1>Welcome to LSD Website</h1>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md='auto'>
                                    <Image style={{'padding':'10px'}}  src="/dock.gif" fluid />
                                </Col>
                                <Col>
                                <Card.Text>
                          
                                     Large Scale Docking (LSD) refers to screening of virtual compound libraries (ZINC15, ZINC22) using the DOCK3.7/3.8 software. This website hosts the results of past LSD campaigns published by the Shoichet Lab at UCSF using the above technology. You can find the IDs of docked molecules and their corresponding docking scores and SMILES,
                                    as well as 3D poses for top scoring molecules, and in vitro results for tested screening molecules from all major campaigns published since 2019. 
                                </Card.Text>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
        </BaseTemplate>
    );
}